import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import {usePageContext} from '@components/Page';
import Meta from '@components/Meta';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import LegalPage from '@components/LegalPage';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  body: Block[];
}

const TermsOfUse = ({data}: {data: Data}): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const {seoTitle, searchDescription, body} = data.cms.page;
  const {draftailCopy} = getDataFromBlockTypes(body);
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <LegalPage {...draftailCopy} />
    </Fragment>
  );
};

export default TermsOfUse;

export const query = graphql`
  fragment TermsOfUseFragment on CMS_LegalPage {
    seoTitle
    searchDescription
    body {
      ...headerBodyFragment
    }
  }
  query TermsOfUse {
    cms {
      page(url: "/home/terms-of-use/") {
        title
        ...TermsOfUseFragment
      }
    }
  }
`;
